var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"bDropdownSelect",staticClass:"account-popup popup",class:_vm.isEmbed ? 'light-theme-act-popup' : ''},[_c('div',{staticClass:"account-container"},[_c('div',{staticClass:"pills"},[(_vm.hasAccountSettingsAccess() && _vm.extAdminHasAccounts())?_c('div',{staticClass:"mp-nav-brand-link",class:[_vm.hasAccountSettingsAccess() && _vm.extAdminHasAccounts() ? '' : 'hide'],on:{"click":_vm.handleAccountSettingsClick}},[_vm._v(" Account Settings ")]):_vm._e(),(_vm.hasBLCampaignAccess())?_c('div',{staticClass:"mp-nav-brand-link",class:[_vm.hasBLCampaignAccess() ? '' : 'hide'],on:{"click":function($event){return _vm.changeSelectedMenuOption(_vm.LAYOUT_TYPES.BL_CAMPAIGN_SETUP)}}},[_vm._v(" Brand Lift ")]):_vm._e(),_c('p',{staticClass:"marginLeft39"},[_c('menu-icon',{attrs:{"icon":['fal', 'sign-out'],"title":"Log Out"},on:{"click":_vm.onClickLogout}})],1)]),(_vm.showAccountList)?_c('div',{staticClass:"mp-nav-div"}):_vm._e(),(_vm.showAccountList)?_c('div',{staticClass:"mp-account-label"},[_c('span',{staticClass:"act-swt-head"},[_vm._v(_vm._s(_vm.header))])]):_vm._e(),(_vm.showAccountList)?_c('div',{staticClass:"inp-wrap"},[_c('k-text-input',{attrs:{"default-focus":true,"autofocus":true,"placeholder":"Start typing..."},on:{"keyup":_vm.onChange}}),_c('font-awesome-icon',{staticClass:"inp-icon",attrs:{"icon":['fal', 'chevron-right']}})],1):_vm._e(),(_vm.showAccountList && !_vm.solutionLoading)?_c('div',{staticClass:"acct-drop-names top-margin"},_vm._l((_vm.filteredOptions),function(item){return _c('div',{key:item.id,staticClass:"account-item",attrs:{"id":item.id},on:{"click":function($event){return _vm.handleAdvDropdown(item.id)}}},[_c('span',[_vm._v(" "+_vm._s(item.id === 73 ? 'Viamedia' : item.name)+" ")]),(item.advertisers.length)?_c('font-awesome-icon',{staticClass:"droparw",attrs:{"icon":[
            'far',
            item.id === _vm.activeAccountID || _vm.searchText.length >= 3 ? 'chevron-up' : 'chevron-down',
          ]}}):_vm._e(),(
            (_vm.activeAccountID === item.id || _vm.searchText.length >= 3) && item.advertisers.length
          )?_c('div',{staticClass:"acct-drop-names"},_vm._l((_vm.filteredAdvertisers(item.advertisers)),function(adv){return _c('div',{key:adv.id,class:[
              'account-item',
              'sub-account-item',
              'no-border',
              { selected: _vm.isOptionSelected(item) },
            ],attrs:{"id":adv.id},on:{"mouseover":function($event){$event.stopPropagation();_vm.$set(_vm.optionHover, item.id, true);
              _vm.optionHovering = true;},"mouseleave":function($event){_vm.$set(_vm.optionHover, item.id, false);
              _vm.optionHovering = false;},"click":function($event){return _vm.onClickOption(item, adv)}}},[_vm._v(" "+_vm._s(adv.name)+" ")])}),0):_vm._e()],1)}),0):_vm._e(),(_vm.solutionLoading)?_c('b-loading-spinner',{staticClass:"solution-loading-spinner"}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }